export type IconType = 'restaurant' | 'insert_image' | 'menu_book' | 'store' | 'info' | 'search' | 'public';

export enum IconEnum {
  WEBSITE = 'public',
  UTENSILS = 'restaurant',
  MENU = 'menu_book',
  STOREFRONT = 'store',
  INFO = 'info',
  SEARCH = 'search',
  HOME = 'home',
}