import * as React from "react";
import { Link } from 'react-router-dom';
import { ToastTab, FreshEats } from "@gwilson/domains";

import {getEstablishmentRoute} from '../routes/routes';
import Card from "./molecules/Card";
import AddressBlock from "./molecules/AddressBlock";
import Icon from "./atoms/Icon";
import { IconEnum } from "../models.d";

const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
const getTimes = (establishment: FreshEats.Establishment) => {
  const dayString = days[new Date().getDay()];
  const todayHours = establishment.hours && establishment.hours[dayString] && establishment.hours[dayString][0];

  return {
    open: todayHours.open.slice(0, 5),
    close: todayHours.close.slice(0, 5),
  };
}

interface Props {
  establishment: FreshEats.Establishment;
}

const EstablishmentCard: React.FC<Props> = ({ establishment }) => {
  const { open, close } = getTimes(establishment);

  return (
    <div className="tile is-ancestor">
      <div className="tile is-parent">
        <article className="tile is-child box hero">

          <p className="title is-3">{establishment.name}</p>
          <p className="subtitle is-5">
            <time dateTime={open}>{open}</time>-<time dateTime={close}>{close}</time>
          </p>
          <div className="hero-footer">
          <Link to={getEstablishmentRoute(establishment)} className="button card-footer">
            Menu
          </Link>
          </div>
        </article>
      </div>
    </div>
  )
};

export default EstablishmentCard;
