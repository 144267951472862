import * as React from "react";
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Layout from './layout/Layout';
import EstablishmentsRoute from "./routes/Establishments";
import { RoutePath } from './routes/routes';
import DetailsRoute from "./routes/Details";

/**
 * TODO: Create function to fetch menus ~3x per day
 * and save to firestore.
 *  - Bypasses CORS and spamming them
 *  - More control over data model (look at sharing between packages)
 */

const App: React.FC = () => {
  const [err, setErr] = React.useState<Error>();

  return (
    <Router>
      
      <Layout banner={err ? (<h1>{err.message}</h1>) : null}>
        <Switch>
          <Route path={RoutePath.HOME} exact component={EstablishmentsRoute}/>
          <Route path={RoutePath.NOT_FOUND} exact render={() => <h1>Establishment not Found</h1>} />
          <Route path={RoutePath.ESTABLISHMENT_DETAILS} component={DetailsRoute} />
          <Redirect to={RoutePath.NOT_FOUND} />
        </Switch>
      </Layout>
    </Router>
  );
};

export default App;
