import * as React from "react";
import { RouteProps } from "react-router-dom";
import { FreshEats } from "@gwilson/domains";

import { getEstablishments } from "../data/queries";

import EstablishmentCard from "../components/EstablishmentCard";

interface Props extends RouteProps {}

const EstablishmentsRoute: React.FC<Props> = () => {
  const [establishments, setEstablishments] = React.useState<
    FreshEats.Establishment[]
  >([]);
  const [err, setErr] = React.useState<Error>();

  React.useEffect(() => {
    async function init() {
      try {
        const establishmentQuery = await getEstablishments();

        const docs = establishmentQuery.docs.map((d) => d.data());
        setEstablishments(docs);
      } catch (e) {
        setErr(e);
      }
    }
    init();
  }, []);

  return (
    <section className="section">
      <div className="container has-text-centered">
        <h2 className="title">Shops</h2>
        <div className="columns is-multiline is-centered">
          {establishments.map((est) => (
            <div  key={`${est.source.id}-${est.source.source_guid}`} className="column is-one-third">
              <EstablishmentCard establishment={est} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default EstablishmentsRoute;
