import * as React from "react";
import { useParams, RouteProps, useHistory } from "react-router-dom";
import { Establishment, MenuItem } from "@gwilson/domains/src/fresh-eats";

import { getEstablishments, getMenuItems } from "../data/queries";

import { RoutePath } from "./routes";

const PLACEHOLDER_IMAGE =
  "https://firebasestorage.googleapis.com/v0/b/fresh-eats-a04bc.appspot.com/o/images%2Fimage-placeholder.png?alt=media&token=637cd001-d825-4152-8479-6becdcce5db9";

interface Props extends RouteProps {}

const DetailsRoute: React.FC<Props> = () => {
  const routeParams = useParams<{ id: string }>();
  const history = useHistory();
  const [establishment, setEstablishment] = React.useState<Establishment>();
  const [menuItems, setMenuItems] = React.useState<MenuItem[]>([]);

  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [err, setErr] = React.useState<Error>();

  React.useEffect(() => {
    async function init() {
      setIsLoading(true);
      try {
        const establishmentQuery = await getEstablishments({
          id: routeParams.id,
        });

        const docs = establishmentQuery.docs.map((d) => d.data());
        if (docs.length > 0) {
          setEstablishment(docs[0]);
        } else {
          history.push(RoutePath.NOT_FOUND);
        }
      } catch (e) {
        setErr(e);
      }
      setIsLoading(false);
    }
    init();
  }, []);

  // MENU ITEMS
  React.useEffect(() => {
    async function init() {
      if (!establishment) return;

      try {
        const itemQuery = await getMenuItems({
          establishmentId: establishment.source.source_guid,
        });
        const docs = itemQuery.docs.map((d) => d.data());
        setMenuItems(docs);
      } catch (e) {
        setErr(e);
      }
    }

    if (establishment) {
      init();
    }
  }, [establishment]);

  if (isLoading) return <div className="is-loading"></div>;
  if (err) return <div>{err.message}</div>;
  if (!establishment) return <></>;

  return (
    <section>
      <div className="columns">
        <h1>{establishment && establishment.name}</h1>
      </div>
      <div className="table-container">
        <table className="table is-fullwidth is-striped">
          <tbody>
            {menuItems.length === 0 && (
              <tr>
                <td>No Items</td>
              </tr>
            )}
            {menuItems.map((item) => (
              <tr key={item.source.source_guid}>
                <td className="is-hidden-mobile is-vcentered">
                  <figure className="is-square level">
                    <img
                      src={item.imageUrl || PLACEHOLDER_IMAGE}
                      className="level-item"
                    />
                  </figure>
                </td>
                <td>
                  <div className="is-mobile is-hidden-desktop  is-hidden-tablet">
                    <figure className="is-square level">
                      <img src={item.imageUrl || PLACEHOLDER_IMAGE} />
                    </figure>
                  </div>
                  <p className="menu-label has-text-weight-semibold">
                    {item.name}
                  </p>
                  <p className="has-text-weight-light">{item.description}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default DetailsRoute;
