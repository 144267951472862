import * as React from 'react';
import { Link } from 'react-router-dom';

import './layout.scss';
import Icon from '../components/atoms/Icon';
import { IconEnum } from '../models.d';
import { RoutePath } from '../routes/routes';

const Layout: React.FC<{ banner: React.ReactNode }> = ({ banner , children }) => {
  return (
    <div className="container">
      {banner && (
        <aside className="content section">{banner}</aside>
      )}
      <section className="section">
        {children}
      </section>
      <footer className="navbar is-fixed-bottom is-transparent">
        <nav className="container" role="navigation" aria-label="main navigation">
          <div className="navbar-brand navbar-content level">
            <Link to={RoutePath.HOME} className="navbar-item">
              <Icon className="icon" type={IconEnum.HOME} />
            </Link>
          </div>
        </nav>
      </footer>
    </div>
  );
};

export default Layout;