import * as React from 'react';
import classnames from 'classnames';

import { IconEnum } from '../../models';

interface Props extends React.ComponentProps<any> {
  type: IconEnum;
}

export default React.memo(({ type, className, ...props }: Props) => (
  <i className={classnames("material-icons", className)} {...props}>{type}</i>
))