import { firestore } from "firebase/app";
import { MenuGroup, Establishment, Menu, MenuItem, CollectionEnum } from "@gwilson/domains/src/fresh-eats";

import { db } from '../services/firebase';


interface GetEstablishments { id?: string, guid?: string }
export const getEstablishments = async ({ id, guid }: GetEstablishments = {}): Promise<firestore.QuerySnapshot<Establishment>> => {
  let ref: any = db.collection(CollectionEnum.ESTABLISHMENTS)
  
  if (id) {
    ref = ref.where('id', '==', id);
  }
  if (guid) {
    ref = ref.where('guid', '==', guid);
  }

  return ref.get();
}

interface GetMenus { establishmentId?: string, id?: string }
export const getMenus = async ({ establishmentId, id }: GetMenus = {}): Promise<firestore.QuerySnapshot<Menu>> => {
  let ref: any = db.collection(CollectionEnum.MENUS)
  
  if (establishmentId) {
    ref = ref.where('establishmentId', '==', establishmentId);
  }
  if (id) {
    ref = ref.where('id', '==', id);
  }

  return ref.get();
}

interface GetMenuGroups { id?: string, establishmentId?: string, menuId?: string }
export const getMenuGroups = async ({ id, establishmentId, menuId }: GetMenuGroups = {}): Promise<firestore.QuerySnapshot<MenuGroup>> => {
  let ref: any = db.collection(CollectionEnum.MENU_GROUPS)
  
  if (id) {
    ref = ref.where('guid', '==', id);
  }
  if (establishmentId) {
    ref = ref.where('establishmentId', '==', establishmentId);
  }
  if (menuId) {
    ref = ref.where('menuId', '==', menuId);
  }

  return ref.get();
}

interface GetMenuItems { id?: string, establishmentId?: string, menuId?: string, menuGroupId?: string }
export const getMenuItems = async ({ id, establishmentId, menuId, menuGroupId }: GetMenuItems = {}): Promise<firestore.QuerySnapshot<MenuItem>> => {
  let ref: any = db.collection(CollectionEnum.MENU_ITEMS)
  
  if (id) {
    ref = ref.where('guid', '==', id);
  }
  if (establishmentId) {
    ref = ref.where('establishmentId', '==', establishmentId);
  }
  if (menuId) {
    ref = ref.where('menuId', '==', menuId);
  }
  if (menuGroupId) {
    ref = ref.where('itemGroupGuid', '==', menuGroupId);
  }

  return ref.get();
}