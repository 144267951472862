import firebase, { firestore, analytics as fbAnalytics } from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

import { prodOnly } from './environment'

const config = {
  apiKey: "AIzaSyDs3J6Jx86AWlzniU0iU6BKuGw-sun5KkY",
  authDomain: "fresh-eats-a04bc.firebaseapp.com",
  databaseURL: "https://fresh-eats-a04bc.firebaseio.com",
  projectId: "fresh-eats-a04bc",
  storageBucket: "fresh-eats-a04bc.appspot.com",
  messagingSenderId: "372845420806",
  appId: "1:372845420806:web:523583eef3a2b9ee723e87",
  measurementId: "G-LX6Q7XTHEG"
};

let _initilized = false;

export const isInitialized = () => _initilized;

if (!_initilized) {
 firebase.initializeApp(config);
 _initilized = true;
}

export const db: firestore.Firestore = firebase.firestore();
export const analytic: fbAnalytics.Analytics = prodOnly(firebase.analytics, {});